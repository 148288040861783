
<template>
  <main>
    <div class="container mt-3 p-3 text-center">
      <h3>{{ $t("labels.terms_of_service") }}</h3>
    </div>

  <div class="container shadow p-3 mb-5 bg-white rounded" v-if="$i18n.locale=='en'">
    
<p>Last updated: 2022-06-17</p>
<p>1. <b>Introduction</b></p>
<p>Welcome to <b>ThemyCV</b> (“Company”, “we”, “our”, “us”)!</p>
<p>These Terms of Service (“Terms”, “Terms of Service”) govern your use of our website located at <b>themycv.com</b> (together or individually “Service”) operated by <b>ThemyCV</b>.</p>
<p>Our Privacy Policy also governs your use of our Service and explains how we collect, safeguard and disclose information that results from your use of our web pages.</p>
<p>Your agreement with us includes these Terms and our Privacy Policy (“Agreements”). You acknowledge that you have read and understood Agreements, and agree to be bound of them.</p>
<p>If you do not agree with (or cannot comply with) Agreements, then you may not use the Service, but please let us know by emailing at <b>themycvbusiness@gmail.com</b> so we can try to find a solution. These Terms apply to all visitors, users and others who wish to access or use Service.</p>
<p>2. <b>Communications</b></p>
<p>By using our Service, you agree to subscribe to newsletters, marketing or promotional materials and other information we may send. However, you may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or by emailing at themycvbusiness@gmail.com.</p>
<p>3. <b>Purchases</b></p>
<p>If you wish to purchase any product or service made available through Service (“Purchase”), you may be asked to supply certain information relevant to your Purchase including but not limited to, your credit or debit card number, the expiration date of your card, your billing address, and your shipping information.</p>
<p>You represent and warrant that: (i) you have the legal right to use any card(s) or other payment method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct and complete.</p>
<p>We may employ the use of third party services for the purpose of facilitating payment and the completion of Purchases. By submitting your information, you grant us the right to provide the information to these third parties subject to our Privacy Policy.</p>
<p>We reserve the right to refuse or cancel your order at any time for reasons including but not limited to: product or service availability, errors in the description or price of the product or service, error in your order or other reasons.</p>
<p>We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal transaction is suspected.</p>
<p>4. <b>Contests, Sweepstakes and Promotions</b></p>
<p>Any contests, sweepstakes or other promotions (collectively, “Promotions”) made available through Service may be governed by rules that are separate from these Terms of Service. If you participate in any Promotions, please review the applicable rules as well as our Privacy Policy. If the rules for a Promotion conflict with these Terms of Service, Promotion rules will apply.</p>
<p>5. <b>Subscriptions</b></p>
<p>Some parts of Service are billed on a subscription basis ("Subscription(s)"). You will be billed in advance on a recurring and periodic basis ("Billing Cycle"). Billing cycles will be set depending on the type of subscription plan you select when purchasing a Subscription.</p>
<p>At the end of each Billing Cycle, your Subscription will automatically renew under the exact same conditions unless you cancel it or ThemyCV cancels it. You may cancel your Subscription renewal either through your online account management page or by contacting themycvbusiness@gmail.com customer support team.</p>
<p>A valid payment method is required to process the payment for your subscription. You shall provide ThemyCV with accurate and complete billing information that may include but not limited to full name, address, state, postal or zip code, telephone number, and a valid payment method information. By submitting such payment information, you automatically authorize ThemyCV to charge all Subscription fees incurred through your account to any such payment instruments.</p>
<p>Should automatic billing fail to occur for any reason, ThemyCV reserves the right to terminate your access to the Service with immediate effect.</p>
<p>6. <b>Free Trial</b></p>
<p>ThemyCV may, at its sole discretion, offer a Subscription with a free trial for a limited period of time ("Free Trial").</p>
<p>You may be required to enter your billing information in order to sign up for Free Trial.</p>
<p>If you do enter your billing information when signing up for Free Trial, you will not be charged by ThemyCV until Free Trial has expired. On the last day of Free Trial period, unless you cancelled your Subscription, you will be automatically charged the applicable Subscription fees for the type of Subscription you have selected.</p>
<p>At any time and without notice, ThemyCV reserves the right to (i) modify Terms of Service of Free Trial offer, or (ii) cancel such Free Trial offer.</p>
<p>7. <b>Fee Changes</b></p>
<p>ThemyCV, in its sole discretion and at any time, may modify Subscription fees for the Subscriptions. Any Subscription fee change will become effective at the end of the then-current Billing Cycle.</p>
<p>ThemyCV will provide you with a reasonable prior notice of any change in Subscription fees to give you an opportunity to terminate your Subscription before such change becomes effective.</p>
<p>Your continued use of Service after Subscription fee change comes into effect constitutes your agreement to pay the modified Subscription fee amount.</p>
<p>8. <b>Refunds</b></p>
<p>We issue refunds for Contracts within <b>15 days</b> of the original purchase of the Contract.</p>
<p>9. <b>Content</b></p>
<p>Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material (“Content”). You are responsible for Content that you post on or through Service, including its legality, reliability, and appropriateness.</p>
<p>By posting Content on or through Service, You represent and warrant that: (i) Content is yours (you own it) and/or you have the right to use it and the right to grant us the rights and license as provided in these Terms, and (ii) that the posting of your Content on or through Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person or entity. We reserve the right to terminate the account of anyone found to be infringing on a copyright.</p>
<p>You retain any and all of your rights to any Content you submit, post or display on or through Service and you are responsible for protecting those rights. We take no responsibility and assume no liability for Content you or any third party posts on or through Service. However, by posting Content using Service you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through Service. You agree that this license includes the right for us to make your Content available to other users of Service, who may also use your Content subject to these Terms.</p>
<p>ThemyCV has the right but not the obligation to monitor and edit all Content provided by users.</p>
<p>In addition, Content found on or through this Service are the property of ThemyCV or used with permission. You may not distribute, modify, transmit, reuse, download, repost, copy, or use said Content, whether in whole or in part, for commercial purposes or for personal gain, without express advance written permission from us.</p>
<p>10. <b>Prohibited Uses</b></p>
<p>You may use Service only for lawful purposes and in accordance with Terms. You agree not to use Service:</p>
<p>0.1. In any way that violates any applicable national or international law or regulation.</p>
<p>0.2. For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content or otherwise.</p>
<p>0.3. To transmit, or procure the sending of, any advertising or promotional material, including any “junk mail”, “chain letter,” “spam,” or any other similar solicitation.</p>
<p>0.4. To impersonate or attempt to impersonate Company, a Company employee, another user, or any other person or entity.</p>
<p>0.5. In any way that infringes upon the rights of others, or in any way is illegal, threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.</p>
<p>0.6. To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of Service, or which, as determined by us, may harm or offend Company or users of Service or expose them to liability.</p>
<p>Additionally, you agree not to:</p>
<p>0.1. Use Service in any manner that could disable, overburden, damage, or impair Service or interfere with any other party’s use of Service, including their ability to engage in real time activities through Service.</p>
<p>0.2. Use any robot, spider, or other automatic device, process, or means to access Service for any purpose, including monitoring or copying any of the material on Service.</p>
<p>0.3. Use any manual process to monitor or copy any of the material on Service or for any other unauthorized purpose without our prior written consent.</p>
<p>0.4. Use any device, software, or routine that interferes with the proper working of Service.</p>
<p>0.5. Introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or technologically harmful.</p>
<p>0.6. Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of Service, the server on which Service is stored, or any server, computer, or database connected to Service.</p>
<p>0.7. Attack Service via a denial-of-service attack or a distributed denial-of-service attack.</p>
<p>0.8. Take any action that may damage or falsify Company rating.</p>
<p>0.9. Otherwise attempt to interfere with the proper working of Service.</p>
<p>11. <b>Analytics</b></p>
<p>We may use third-party Service Providers to monitor and analyze the use of our Service.</p>
<p>12. <b>No Use By Minors</b></p>
<p>Service is intended only for access and use by individuals at least eighteen (18) years old. By accessing or using Service, you warrant and represent that you are at least eighteen (18) years of age and with the full authority, right, and capacity to enter into this agreement and abide by all of the terms and conditions of Terms. If you are not at least eighteen (18) years old, you are prohibited from both the access and usage of Service.</p>
<p>13. <b>Accounts</b></p>
<p>When you create an account with us, you guarantee that you are above the age of 18, and that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on Service.</p>
<p>You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, whether your password is with our Service or a third-party service. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>
<p>You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you, without appropriate authorization. You may not use as a username any name that is offensive, vulgar or obscene.</p>
<p>We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders in our sole discretion.</p>
<p>14. <b>Intellectual Property</b></p>
<p>Service and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of ThemyCV and its licensors. Service is protected by copyright, trademark, and other laws of  and foreign countries. Our trademarks may not be used in connection with any product or service without the prior written consent of ThemyCV.</p>
<p>15. <b>Copyright Policy</b></p>
<p>We respect the intellectual property rights of others. It is our policy to respond to any claim that Content posted on Service infringes on the copyright or other intellectual property rights (“Infringement”) of any person or entity.</p>
<p>If you are a copyright owner, or authorized on behalf of one, and you believe that the copyrighted work has been copied in a way that constitutes copyright infringement, please submit your claim via email to themycvbusiness@gmail.com, with the subject line: “Copyright Infringement” and include in your claim a detailed description of the alleged Infringement as detailed below, under “DMCA Notice and Procedure for Copyright Infringement Claims”</p>
<p>You may be held accountable for damages (including costs and attorneys’ fees) for misrepresentation or bad-faith claims on the infringement of any Content found on and/or through Service on your copyright.</p>
<p>16. <b>DMCA Notice and Procedure for Copyright Infringement Claims</b></p>
<p>You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing our Copyright Agent with the following information in writing (see 17 U.S.C 512(c)(3) for further detail):</p>
<p>0.1. an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright’s interest;</p>
<p>0.2. a description of the copyrighted work that you claim has been infringed, including the URL (i.e., web page address) of the location where the copyrighted work exists or a copy of the copyrighted work;</p>
<p>0.3. identification of the URL or other specific location on Service where the material that you claim is infringing is located;</p>
<p>0.4. your address, telephone number, and email address;</p>
<p>0.5. a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;</p>
<p>0.6. a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf.</p>
<p>You can contact our Copyright Agent via email at themycvbusiness@gmail.com.</p>
<p>17. <b>Error Reporting and Feedback</b></p>
<p>You may provide us either directly at themycvbusiness@gmail.com or via third party sites and tools with information and feedback concerning errors, suggestions for improvements, ideas, problems, complaints, and other matters related to our Service (“Feedback”). You acknowledge and agree that: (i) you shall not retain, acquire or assert any intellectual property right or other right, title or interest in or to the Feedback; (ii) Company may have development ideas similar to the Feedback; (iii) Feedback does not contain confidential information or proprietary information from you or any third party; and (iv) Company is not under any obligation of confidentiality with respect to the Feedback. In the event the transfer of the ownership to the Feedback is not possible due to applicable mandatory laws, you grant Company and its affiliates an exclusive, transferable, irrevocable, free-of-charge, sub-licensable, unlimited and perpetual right to use (including copy, modify, create derivative works, publish, distribute and commercialize) Feedback in any manner and for any purpose.</p>
<p>18. <b>Links To Other Web Sites</b></p>
<p>Our Service may contain links to third party web sites or services that are not owned or controlled by ThemyCV.</p>
<p>ThemyCV has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third party web sites or services. We do not warrant the offerings of any of these entities/individuals or their websites.</p>
<!--
<p>For example, the outlined <a href="https://policymaker.io/terms-and-conditions/">Terms of Use</a> have been created using <a href="https://policymaker.io/">PolicyMaker.io</a>, a free web application for generating high-quality legal documents. PolicyMaker’s <a href="https://policymaker.io/terms-and-conditions/">Terms and Conditions generator</a> is an easy-to-use free tool for creating an excellent standard Terms of Service template for a website, blog, e-commerce store or app.</p>
-->
<p>YOU ACKNOWLEDGE AND AGREE THAT COMPANY SHALL NOT BE RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD PARTY WEB SITES OR SERVICES.</p>
<p>WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY POLICIES OF ANY THIRD PARTY WEB SITES OR SERVICES THAT YOU VISIT.</p>
<p>19. <b>Disclaimer Of Warranty</b></p>
<p>THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS AVAILABLE” BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES, OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF THESE SERVICES, THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.</p>
<p>NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES, THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</p>
<p>COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.</p>
<p>THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
<p>20. <b>Limitation Of Liability</b></p>
<p>EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES (INCLUDING ATTORNEYS’ FEES AND ALL RELATED COSTS AND EXPENSES OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE PART OF COMPANY, IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.</p>
<p>21. <b>Termination</b></p>
<p>We may terminate or suspend your account and bar access to Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of Terms.</p>
<p>If you wish to terminate your account, you may simply discontinue using Service.</p>
<p>All provisions of Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>
<p>22. <b>Governing Law</b></p>
<p>These Terms shall be governed and construed in accordance with the laws of cabo verde, which governing law applies to agreement without regard to its conflict of law provisions.</p>
<p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service and supersede and replace any prior agreements we might have had between us regarding Service.</p>
<p>23. <b>Changes To Service</b></p>
<p>We reserve the right to withdraw or amend our Service, and any service or material we provide via Service, in our sole discretion without notice. We will not be liable if for any reason all or any part of Service is unavailable at any time or for any period. From time to time, we may restrict access to some parts of Service, or the entire Service, to users, including registered users.</p>
<p>24. <b>Amendments To Terms</b></p>
<p>We may amend Terms at any time by posting the amended terms on this site. It is your responsibility to review these Terms periodically.</p>
<p>Your continued use of the Platform following the posting of revised Terms means that you accept and agree to the changes. You are expected to check this page frequently so you are aware of any changes, as they are binding on you.</p>
<p>By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use Service.</p>
<p>25. <b>Waiver And Severability</b></p>
<p>No waiver by Company of any term or condition set forth in Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of Company to assert a right or provision under Terms shall not constitute a waiver of such right or provision.</p>
<p>If any provision of Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of Terms will continue in full force and effect.</p>
<p>26. <b>Acknowledgement</b></p>
<p>BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.</p>
<p>27. <b>Contact Us</b></p>
<p>Please send your feedback, comments, requests for technical support by email: <b>themycvbusiness@gmail.com</b>.</p>
<!--
<p style="margin-top: 5em; font-size: 0.7em;">These <a href="https://policymaker.io/terms-and-conditions/">Terms of Service</a> were created for <b>themycv.com</b> by <a href="https://policymaker.io">PolicyMaker.io</a> on 2022-06-17.</p>
-->
  </div>

  <div class="container shadow p-3 mb-5 bg-white rounded" v-else>
<p>Última atualização: 17/06/2022</p>
<p>1. <b>Introdução</b></p>
<p>Bem-vindo ao <b>ThemyCV</b> (“Empresa”, “nós”, “nosso”, “nos”)!</p>
<p> Estes Termos de Serviço ("Termos", "Termos de Serviço") regem o uso do nosso site localizado em <b>themycv.com</b> (em conjunto ou individualmente "Serviço") operado por <b>ThemyCV</b >.</p>
<p>Nossa Política de Privacidade também rege o uso do nosso Serviço e explica como coletamos, protegemos e divulgamos informações resultantes do uso de nossas páginas da web.</p>
<p>Seu acordo conosco inclui estes Termos e nossa Política de Privacidade (“Acordos”). Você reconhece que leu e entendeu os Contratos e concorda em ficar vinculado a eles.</p>
<p>Se não concordar (ou não puder cumprir) os Contratos, não poderá usar o Serviço, mas deixe nos conheça enviando um e-mail para <b>themycvbusiness@gmail.com</b> para que possamos tentar encontrar uma solução. Estes Termos se aplicam a todos os visitantes, usuários e outros que desejam acessar ou usar o Serviço.</p>
<p>2. <b>Comunicação</b></p>
<p>Ao usar nosso Serviço, você concorda em assinar boletins informativos, materiais de marketing ou promocionais e outras informações que possamos enviar. No entanto, você pode optar por não receber qualquer uma ou todas essas comunicações de nós seguindo o link de cancelamento de inscrição ou enviando um e-mail para themycvbusiness@gmail.com.</p>
<p>3. <b>Compras</b></p>
<p>Se você deseja adquirir qualquer produto ou serviço disponibilizado por meio do Serviço ("Compra"), pode ser solicitado que você forneça determinadas informações relevantes para sua Compra, incluindo, mas não limitado a para o número do seu cartão de crédito ou débito, a data de validade do seu cartão, seu endereço de cobrança e suas informações de envio.</p>
<p>Você declara e garante que: (i) você tem o direito legal de usar qualquer cartão (s) ou outro(s) método(s) de pagamento em conexão com qualquer Compra; e que (ii) as informações que você nos fornece são verdadeiras, corretas e completas.</p>
<p>Podemos empregar o uso de serviços de terceiros com a finalidade de facilitar o pagamento e a conclusão de Compras. Ao enviar suas informações, você nos concede o direito de fornecer as informações a esses terceiros sujeitos à nossa Política de Privacidade.</p>
<p>Reservamos o direito de recusar ou cancelar seu pedido a qualquer momento por motivos que incluem, entre outros, para: disponibilidade do produto ou serviço, erros na descrição ou preço do produto ou serviço, erro em seu pedido ou outros motivos.</p>
<p>Reservamos o direito de recusar ou cancelar seu pedido em caso de fraude ou há suspeita de transação ilegal.</p>
<p>4. <b>Concursos, Sorteios e Promoções</b></p>
<p>Quaisquer concursos, sorteios ou outras promoções (coletivamente, “Promoções”) disponibilizados por meio do Serviço podem ser regidos por regras separadas destes Termos de Serviço . Se você participar de quaisquer Promoções, revise as regras aplicáveis, bem como nossa Política de Privacidade. Se as regras de uma Promoção entrarem em conflito com estes Termos de Serviço, as regras da Promoção serão aplicadas.</p>
<p>5. <b>Assinaturas</b></p>
<p>Algumas partes do Serviço são cobradas por assinatura ("Assinatura(s)"). Você será cobrado antecipadamente de forma recorrente e periódica ("Ciclo de Cobrança"). Os ciclos de cobrança serão definidos dependendo do tipo de plano de assinatura que você selecionar ao comprar uma Assinatura.</p>
<p>No final de cada Ciclo de cobrança, sua Assinatura será renovada automaticamente nas mesmas condições, a menos que você a cancele ou ThemyCV o cancela. Você pode cancelar a renovação da assinatura por meio da página de gerenciamento de conta on-line ou entrando em contato com a equipe de suporte ao cliente mycvbusiness@gmail.com.</p>
<p>É necessário um método de pagamento válido para processar o pagamento da sua assinatura. Você deve fornecer ao ThemyCV informações de cobrança precisas e completas que podem incluir, entre outras, nome completo, endereço, estado, código postal ou postal, número de telefone e informações válidas sobre o método de pagamento. Ao enviar essas informações de pagamento, você autoriza automaticamente o ThemyCV a cobrar todas as taxas de assinatura incorridas por meio de sua conta em quaisquer desses instrumentos de pagamento.</p>
<p>Se o faturamento automático não ocorrer por qualquer motivo, o ThemyCV se reserva o direito de encerrar seu acesso ao Serviço com efeito imediato.</p>
<p>6. <b>Avaliação Gratuita</b></p>
<p>ThemyCV pode, a seu exclusivo critério, oferecer uma Assinatura com avaliação gratuita por um período limitado de tempo ("Avaliação Gratuita").</p><p >Você pode ser solicitado a inserir suas informações de cobrança para se inscrever no Teste Gratuito.</p>
<p>Se você inserir suas informações de cobrança ao se inscrever no Teste Gratuito, você não será cobrado pelo ThemyCV até o Teste Gratuito expirou. No último dia do período de Avaliação Gratuita, a menos que você tenha cancelado sua Assinatura, serão cobradas automaticamente as taxas de Assinatura aplicáveis para o tipo de Assinatura que você selecionou.</p>
<p>A qualquer momento e sem aviso prévio, ThemyCV se reserva a direito de (i) modificar os Termos de Serviço da oferta de Avaliação Gratuita ou (ii) cancelar tal oferta de Avaliação Gratuita.</p>
<p>7. <b>Alterações nas Taxas</b></p>
<p>ThemyCV, a seu exclusivo critério e a qualquer momento, pode modificar as taxas de Assinatura para as Assinaturas. Qualquer alteração na taxa de assinatura entrará em vigor no final do ciclo de cobrança atual.</p>
<p>ThemyCV fornecerá a você um aviso prévio razoável de qualquer alteração nas taxas de assinatura para dar a você a oportunidade de rescindir sua assinatura antes tal alteração entrará em vigor.</p>
<p>Seu uso continuado do Serviço após a alteração da taxa de assinatura entrar em vigor constitui sua concordância em pagar o valor da taxa de assinatura modificada.</p>
<p>8. <b>Reembolsos</b></p>
<p>Emitimos reembolsos para Contratos dentro de <b>15 dias</b> da compra original do Contrato.</p>
<p>9. <b>Conteúdo</b></p>
<p>Nosso Serviço permite que você publique, vincule, armazene, compartilhe e disponibilize determinadas informações, textos, gráficos, vídeos ou outros materiais (“Conteúdo”). Você é responsável pelo Conteúdo que publica no Serviço ou por meio dele, incluindo sua legalidade, confiabilidade e adequação.</p>
<p>Ao publicar Conteúdo no Serviço ou por meio dele, Você declara e garante que: (i) o Conteúdo é seu ( você o possui) e/ou você tem o direito de usá-lo e o direito de nos conceder os direitos e a licença conforme previsto nestes Termos, e (ii) que a publicação de seu Conteúdo no ou através do Serviço não viola os direitos de privacidade , direitos de publicidade, direitos autorais, direitos contratuais ou quaisquer outros direitos de qualquer pessoa ou entidade. Reservamo-nos o direito de encerrar a conta de qualquer pessoa que infrinja direitos autorais.</p>
<p>Você retém todo e qualquer direito sobre qualquer Conteúdo que enviar, publicar ou exibir no Serviço ou por meio dele e é responsável para proteger esses direitos. Não assumimos nenhuma responsabilidade e não assumimos nenhuma responsabilidade pelo Conteúdo que você ou quaisquer terceiros publicam no Serviço ou por meio dele. No entanto, ao postar Conteúdo usando o Serviço, você nos concede o direito e a licença para usar, modificar, executar publicamente, exibir publicamente, reproduzir e distribuir tal Conteúdo no Serviço e por meio dele. Você concorda que esta licença inclui o direito de disponibilizarmos seu Conteúdo a outros usuários do Serviço, que também podem usar seu Conteúdo sujeito a estes Termos.</p>
<p>ThemyCV tem o direito, mas não a obrigação, de monitorar e editar todo o Conteúdo fornecido pelos usuários.</p>
<p>Além disso, o Conteúdo encontrado neste Serviço ou através dele é propriedade de ThemyCV ou usado com permissão. Você não pode distribuir, modificar, transmitir, reutilizar, baixar, repassar, copiar ou usar o referido Conteúdo, no todo ou em parte, para fins comerciais ou para ganho pessoal, sem autorização prévia expressa por escrito de nós.</p>
<p>10. <b>Usos Proibidos</b></p>
<p>Você pode usar o Serviço apenas para fins legais e de acordo com os Termos. Você concorda em não usar o Serviço:</p>
<p>0.1. De qualquer forma que viole qualquer lei ou regulamento nacional ou internacional aplicável.</p>
<p>0.2. Com o objetivo de explorar, prejudicar ou tentar explorar ou prejudicar menores de qualquer forma, expondo-os a conteúdo impróprio ou de outra forma.</p>
<p>0.3. Para transmitir ou obter o envio de qualquer material publicitário ou promocional, incluindo qualquer "lixo eletrônico", "corrente", "spam" ou qualquer outra solicitação semelhante.</p>
<p>0.4. Para se passar ou tentar se passar pela Empresa, um funcionário da Empresa, outro usuário ou qualquer outra pessoa ou entidade.</p>
<p>0.5. De qualquer forma que infrinja os direitos de terceiros, ou de qualquer forma que seja ilegal, ameaçadora, fraudulenta ou prejudicial, ou em conexão com qualquer finalidade ou atividade ilegal, ilegal, fraudulenta ou prejudicial.</p>
<p>0.6 . Para se envolver em qualquer outra conduta que restrinja ou iniba o uso ou aproveitamento do Serviço por qualquer pessoa, ou que, conforme determinado por nós, possa prejudicar ou ofender a Empresa ou os usuários do Serviço ou expô-los a responsabilidades.</p>
<p>Além disso, você concorda em não:</p>
<p>0.1. Usar o Serviço de qualquer maneira que possa desabilitar, sobrecarregar, danificar ou prejudicar o Serviço ou interferir no uso do Serviço por qualquer outra parte, incluindo sua capacidade de participar de atividades em tempo real por meio do Serviço.</p>
<p>0.2. Use qualquer robô, spider ou outro dispositivo, processo ou meio automático para acessar o Serviço para qualquer finalidade, incluindo monitorar ou copiar qualquer material no Serviço.</p>
<p>0.3. Use qualquer processo manual para monitorar ou copiar qualquer material no Serviço ou para qualquer outra finalidade não autorizada sem nosso consentimento prévio por escrito.</p>
<p>0.4. Use qualquer dispositivo, software ou rotina que interfira no funcionamento adequado do Serviço.</p>
<p>0.5. Introduza quaisquer vírus, cavalos de tróia, worms, bombas lógicas ou outros materiais maliciosos ou tecnologicamente prejudiciais.</p>
<p>0.6. Tentar obter acesso não autorizado, interferir, danificar ou interromper qualquer parte do Serviço, o servidor no qual o Serviço está armazenado ou qualquer servidor, computador ou banco de dados conectado ao Serviço.</p>
<p>0.7. Serviço de ataque por meio de um ataque de negação de serviço ou um ataque distribuído de negação de serviço.</p>
<p>0.8. Tome qualquer medida que possa danificar ou falsificar a classificação da Empresa.</p>
<p>0,9. Caso contrário, tente interferir no funcionamento adequado do Serviço.</p>
<p>11. <b>Analytics</b></p>
<p>Podemos usar provedores de serviços terceirizados para monitorar e analisar o uso de nosso serviço.</p>
<p>12. <b>Proibição de uso por menores</b></p>
<p>O serviço destina-se apenas ao acesso e uso por indivíduos com pelo menos dezoito (18) anos de idade. Ao acessar ou usar o Serviço, você garante e declara que tem pelo menos dezoito (18) anos de idade e tem plena autoridade, direito e capacidade para celebrar este contrato e cumprir todos os termos e condições dos Termos. Se você não tiver pelo menos dezoito (18) anos, está proibido de acessar e usar o Serviço.</p>
<p>13. <b>Contas</b></p>
<p>Ao criar uma conta conosco, você garante que tem mais de 18 anos e que as informações que nos fornece são precisas, completas e atuais vezes. Informações imprecisas, incompletas ou obsoletas podem resultar no encerramento imediato de sua conta no Serviço.</p>
<p>Você é responsável por manter a confidencialidade de sua conta e senha, incluindo, mas não se limitando à restrição de acesso ao seu computador e/ou conta. Você concorda em aceitar a responsabilidade por todas e quaisquer atividades ou ações que ocorram em sua conta e/ou senha, seja sua senha com nosso Serviço ou um serviço de terceiros. Você deve nos notificar imediatamente ao tomar conhecimento de qualquer violação de segurança ou uso não autorizado de sua conta.</p>
<p>Você não pode usar como nome de usuário o nome de outra pessoa ou entidade ou que não esteja legalmente disponível para uso, um nome ou marca comercial que esteja sujeito a quaisquer direitos de outra pessoa ou entidade que não você, sem a devida autorização. Você não pode usar como nome de usuário qualquer nome que seja ofensivo, vulgar ou obsceno.</p>
<p>Reservamos o direito de recusar o serviço, encerrar contas, remover ou editar conteúdo ou cancelar pedidos a nosso critério.</p> 
<p>14. <b>Propriedade Intelectual</b></p>
<p>Serviço e seu conteúdo original (excluindo Conteúdo fornecido pelos usuários), recursos e funcionalidades são e permanecerão de propriedade exclusiva de ThemyCV e seus licenciadores. O serviço é protegido por direitos autorais, marcas registradas e outras leis de países estrangeiros. Nossas marcas registradas não podem ser usadas em conexão com qualquer produto ou serviço sem o consentimento prévio por escrito da ThemyCV.</p>
<p>15. <b>Política de direitos autorais</b></p>
<p>Respeitamos os direitos de propriedade intelectual de terceiros. É nossa política responder a qualquer reclamação de que o Conteúdo postado no Serviço infrinja os direitos autorais ou outros direitos de propriedade intelectual (“Violação”) de qualquer pessoa ou entidade.</p>
<p>Se você for proprietário de direitos autorais ou autorizado em nome de um, e você acredita que o trabalho protegido por direitos autorais foi copiado de forma que constitua violação de direitos autorais, envie sua reivindicação por e-mail para themycvbusiness@gmail.com, com o assunto: "Violação de direitos autorais" e inclua em sua reivindicação uma descrição detalhada da suposta violação conforme detalhado abaixo, em "Aviso e procedimento da DMCA para reivindicações de violação de direitos autorais"</p>
<p>Você pode ser responsabilizado por danos (incluindo custos e honorários advocatícios) por declarações falsas ou má-fé reivindicações sobre a violação de qualquer Conteúdo encontrado no e/ou através do Serviço em seus direitos autorais.</p>
<p>16. <b>Aviso e Procedimento da DMCA para Reivindicações de Violação de Direitos Autorais</b></p>
<p>Você pode enviar uma notificação de acordo com a Lei de Direitos Autorais do Milênio Digital (DMCA) fornecendo ao nosso Agente de Direitos Autorais as seguintes informações por escrito (consulte 17 USC 512(c)(3) para mais detalhes):</p>
<p>0.1. uma assinatura eletrônica ou física da pessoa autorizada a agir em nome do titular dos direitos autorais;</p>
<p>0.2. uma descrição do trabalho protegido por direitos autorais que você alega ter sido violado, incluindo o URL (ou seja, o endereço da página da web) do local onde o trabalho protegido por direitos autorais existe ou uma cópia do trabalho protegido por direitos autorais;</p>
<p>0.3. identificação do URL ou outro local específico no Serviço onde o material que você alega estar infringindo está localizado;</p>
<p>0.4. seu endereço, número de telefone e endereço de e-mail;</p>
<p>0.5. uma declaração sua de que você acredita de boa fé que o uso contestado não está autorizado pelo proprietário dos direitos autorais, seu agente ou pela lei;</p>
<p>0.6. uma declaração sua, feita sob pena de perjúrio, de que as informações acima em seu aviso são precisas e que você é o proprietário dos direitos autorais ou está autorizado a agir em nome do proprietário dos direitos autorais.</p>
<p>Você pode entrar em contato com nosso Agente de direitos autorais por e-mail para themycvbusiness@gmail.com.</p>
<p>17. <b>Relatório de erros e feedback</b></p>
<p>Você pode nos fornecer diretamente em themycvbusiness@gmail.com ou por meio de sites e ferramentas de terceiros com informações e feedback sobre erros, sugestões de melhorias, ideias, problemas, reclamações e outros assuntos relacionados ao nosso Serviço (“Feedback”). Você reconhece e concorda que: (i) você não deve reter, adquirir ou reivindicar qualquer direito de propriedade intelectual ou outro direito, título ou interesse no Feedback; (ii) a Empresa pode ter ideias de desenvolvimento semelhantes ao Feedback; (iii) O feedback não contém informações confidenciais ou informações proprietárias suas ou de terceiros; e (iv) a Empresa não tem nenhuma obrigação de confidencialidade com relação ao Feedback. Caso a transferência da propriedade para o Feedback não seja possível devido às leis obrigatórias aplicáveis, você concede à Empresa e suas afiliadas um direito exclusivo, transferível, irrevogável, gratuito, sublicenciável, ilimitado e perpétuo de uso ( incluindo copiar, modificar, criar trabalhos derivados, publicar, distribuir e comercializar) Feedback de qualquer maneira e para qualquer finalidade.</p>
<p>18. <b>Links para outros sites</b></p>
<p>Nosso serviço pode conter links para sites ou serviços de terceiros que não são de propriedade ou controlados por ThemyCV.</p>
<p>ThemyCV não possui controle e não assume nenhuma responsabilidade pelo conteúdo, políticas de privacidade ou práticas de quaisquer sites ou serviços de terceiros. Não garantimos as ofertas de nenhuma dessas entidades/indivíduos ou seus sites.</p>
<!--
<p>Por exemplo, o descrito <a href="https://policymaker.io/terms-and-conditions/"> Os Termos de Uso</a> foram criados usando o <a href="https://policymaker.io/">PolicyMaker.io</a>, um aplicativo da Web gratuito para gerar documentos jurídicos de alta qualidade. O <a href="https://policymaker.io/terms-and-conditions/">gerador de Termos e Condições</a> do PolicyMaker é uma ferramenta gratuita e fácil de usar para criar um excelente modelo de Termos de Serviço padrão para um site, blog, loja de comércio eletrônico ou aplicativo.</p>
-->
<p>VOCÊ RECONHECE E CONCORDA QUE A EMPRESA NÃO SERÁ RESPONSÁVEL, DIRETA OU INDIRETAMENTE, POR QUALQUER DANO OU PERDA CAUSADA OU ALEGADA CAUSADA POR OU EM CONEXÃO COM O USO OU CONFIANÇA EM QUALQUER CONTEÚDO, BENS OU SERVIÇOS DISPONÍVEIS EM OU ATRAVÉS DE QUAISQUER SITES OU SERVIÇOS DE TERCEIROS.</p>
<p>ACONSELHAMOS FORTEMENTE QUE VOCÊ LEIA OS TERMOS DE SERVIÇO E POLÍTICAS DE PRIVACIDADE DE QUALQUER TERCEIRO SITES OU SERVIÇOS QUE VOCÊ VISITA.</p>
<p>19. <b>Isenção de Garantia</b></p>
<p>ESTES SERVIÇOS SÃO FORNECIDOS PELA EMPRESA “COMO ESTÃO” E “CONFORME DISPONÍVEIS”. A EMPRESA NÃO FAZ REPRESENTAÇÕES OU GARANTIAS DE QUALQUER TIPO, EXPRESSAS OU IMPLÍCITAS, QUANTO À OPERAÇÃO DE SEUS SERVIÇOS, OU AS INFORMAÇÕES, CONTEÚDO OU MATERIAIS INCLUÍDOS NELE. VOCÊ CONCORDA EXPRESSAMENTE QUE O USO DESTES SERVIÇOS, SEU CONTEÚDO E QUAISQUER SERVIÇOS OU ITENS OBTIDOS DE NÓS É POR SUA CONTA E RISCO.</p>
<p>NEM A EMPRESA NEM QUALQUER PESSOA ASSOCIADA À EMPRESA FAZ QUALQUER GARANTIA OU REPRESENTAÇÃO COM RELAÇÃO AO A INTEGRALIDADE, SEGURANÇA, CONFIABILIDADE, QUALIDADE, PRECISÃO OU DISPONIBILIDADE DOS SERVIÇOS. SEM LIMITAR O ANTERIOR, NEM A EMPRESA NEM QUALQUER UM ASSOCIADO À EMPRESA REPRESENTA OU GARANTE QUE OS SERVIÇOS, SEU CONTEÚDO OU QUAISQUER SERVIÇOS OU ITENS OBTIDOS ATRAVÉS DOS SERVIÇOS SERÃO PRECISOS, CONFIÁVEIS, LIVRES DE ERROS OU ININTERRUPTOS, QUE OS DEFEITOS SERÃO CORRIGIDOS, QUE OS SERVIÇOS OU O SERVIDOR QUE O DISPONIBILIZA ESTÃO LIVRES DE VÍRUS OU OUTROS COMPONENTES PREJUDICIAIS OU QUE OS SERVIÇOS OU QUAISQUER SERVIÇOS OU ITENS OBTIDOS ATRAVÉS DOS SERVIÇOS ATENDERÃO ÀS SUAS NECESSIDADES OU EXPECTATIVAS.</p>
<p>A EMPRESA SE ISENTA DE TUDO GARANTIAS DE QUALQUER TIPO, EXPRESSAS OU IMPLÍCITAS, ESTATUTÁRIAS OU OUTRAS, INCLUINDO MAS NÃO LIMITADO A QUAISQUER GARANTIAS DE COMERCIALIZAÇÃO, NÃO INFRAÇÃO E ADEQUAÇÃO A UM DETERMINADO FIM.</p>
<p>O PRECEDENTE NÃO AFETA QUAISQUER GARANTIAS QUE NÃO PODE SER EXCLUÍDO OU LIMITADO PELA LEI APLICÁVEL.</p>
<p>20. <b>Limitação de responsabilidade</b></p>
<p>EXCETO SE PROIBIDO POR LEI, VOCÊ IRÁ ISENTAR A NÓS E NOSSOS DIRETORES, DIRETORES, FUNCIONÁRIOS E AGENTES POR QUALQUER INDIRETO, PUNITIVO, ESPECIAL, INCIDENTAL OU CONSEQUENCIAL DANOS, INDEPENDENTEMENTE DE QUE SEJAM DECORRENTES (INCLUINDO HONORÁRIOS DE ADVOGADOS E TODOS OS CUSTOS E DESPESAS RELACIONADAS COM LITÍGIO E ARBITRAGEM, OU EM JULGAMENTO OU RECURSO, SE HOUVER, QUER O LITÍGIO OU A ARBITRAGEM ESTEJA INSTITUÍDA), SEJA EM UMA AÇÃO DE CONTRATO, NEGLIGÊNCIA, OU OUTRA AÇÃO ILÍCITA, OU DECORRENTE DE OU EM CONEXÃO COM ESTE CONTRATO, INCLUINDO, SEM LIMITAÇÃO, QUALQUER REIVINDICAÇÃO POR DANOS PESSOAIS OU DANOS DE PROPRIEDADE, DECORRENTES DESTE CONTRATO E QUALQUER VIOLAÇÃO POR VOCÊ DE QUAISQUER LEIS, ESTATUTOS, REGRAS FEDERAIS, ESTADUAIS OU LOCAIS , OU REGULAMENTOS, MESMO QUE A EMPRESA TENHA SIDO PREVIAMENTE AVISADA DA POSSIBILIDADE DE TAIS DANOS. SALVO PROIBIDO POR LEI, SE HOUVER RESPONSABILIDADE DA COMPANHIA, ESTARÁ LIMITADA AO VALOR PAGO PELOS PRODUTOS E/OU SERVIÇOS, E EM NENHUMA CIRCUNSTÂNCIA HAVERÁ DANOS CONSEQUENCIAIS OU PUNITIVOS. ALGUNS ESTADOS NÃO PERMITEM A EXCLUSÃO OU LIMITAÇÃO DE DANOS PUNITIVOS, INCIDENTAIS OU CONSEQUENTES, PORTANTO A LIMITAÇÃO OU EXCLUSÃO ANTERIOR PODE NÃO SE APLICAR A VOCÊ.</p>
<p>21. <b>Rescisão</b></p>
<p>Podemos encerrar ou suspender sua conta e barrar o acesso ao Serviço imediatamente, sem aviso prévio ou responsabilidade, a nosso exclusivo critério, por qualquer motivo e sem limitação, incluindo, mas não se limitando a uma violação dos Termos.</p>
<p>Se você deseja encerrar sua conta, você pode simplesmente descontinuar o uso do Serviço.</p>
<p>Todas as disposições dos Termos que, por sua natureza, devem sobreviver à rescisão, sobreviverão rescisão, incluindo, sem limitação, disposições de propriedade, isenções de garantia, indenização e limitações de responsabilidade.</p>
<p>22. <b>Lei Aplicável</b></p>
<p>Estes Termos serão regidos e interpretados de acordo com as leis de Cabo Verde, cuja lei aplicável se aplica ao contrato sem considerar o conflito de disposições legais.</p ><p>Nossa falha em fazer valer qualquer direito ou disposição destes Termos não será considerada uma renúncia a esses direitos. Se qualquer disposição destes Termos for considerada inválida ou inexequível por um tribunal, as demais disposições destes Termos permanecerão em vigor. Estes Termos constituem o acordo integral entre nós em relação ao nosso Serviço e substituem quaisquer acordos anteriores que possamos ter tido entre nós em relação ao Serviço.</p>
<p>23. <b>Mudanças no Serviço</b></p>
<p>Reservamos o direito de retirar ou alterar nosso Serviço e qualquer serviço ou material que fornecermos por meio do Serviço, a nosso critério, sem aviso prévio. Não seremos responsáveis se, por qualquer motivo, todo ou parte do Serviço estiver indisponível a qualquer momento ou por qualquer período. De tempos em tempos, podemos restringir o acesso a algumas partes do Serviço, ou a todo o Serviço, a usuários, incluindo usuários registrados.</p>
<p>24. <b>Alterações aos Termos</b></p>
<p>Podemos alterar os Termos a qualquer momento publicando os termos alterados neste site. É sua responsabilidade revisar estes Termos periodicamente.</p>
<p>Seu uso continuado da Plataforma após a publicação dos Termos revisados significa que você aceita e concorda com as alterações. Espera-se que você verifique esta página com frequência para estar ciente de quaisquer alterações, pois elas são vinculativas para você.</p>
<p>Ao continuar acessando ou usando nosso Serviço após a entrada em vigor de quaisquer revisões, você concorda em ficar vinculado a os termos revistos. Se você não concordar com os novos termos, não estará mais autorizado a usar o Serviço.</p>
<p>25. <b>Renúncia e Separabilidade</b></p>
<p>Nenhuma renúncia por parte da Empresa de qualquer termo ou condição estabelecida nos Termos será considerada uma renúncia adicional ou contínua de tal termo ou condição ou uma renúncia de qualquer outro termo ou condição, e qualquer falha da Empresa em reivindicar um direito ou disposição sob os Termos não constituirá uma renúncia de tal direito ou disposição.</p>
<p>Se qualquer disposição dos Termos for mantida por um tribunal ou outro tribunal de jurisdição competente ser inválida, ilegal ou inexequível por qualquer motivo, tal disposição será eliminada ou limitada ao mínimo necessário para que as demais disposições dos Termos continuem em pleno vigor e efeito.</p>
<p>26. <b>Reconhecimento</b></p>
<p>AO USAR O SERVIÇO OU OUTROS SERVIÇOS FORNECIDOS POR NÓS, VOCÊ RECONHECE QUE LEU ESTES TERMOS DE SERVIÇO E CONCORDA EM FICAR VINCULADO POR ELES.</p>
<p> 27. <b>Fale Conosco</b></p>
<p>Envie seus comentários, comentários, solicitações de suporte técnico por e-mail: <b>themycvbusiness@gmail.com</b>.</p>
<!--
  <p style="margin-top: 5em; font-size: 0.7em;">Estes <a href="https://policymaker.io/terms-and-conditions/">Termos de Serviço</a> foram criados para <b>themycv.com</b> por <a href="https://policymaker.io">PolicyMaker.io</a> em 17/06/2022.</p>
--> 
</div>

  </main>
</template>

<script>



export default {
  name: "Contact",
  


 
  
};
</script>

<style scoped>

</style>
